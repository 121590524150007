<template>
  <b-modal
    id="create-booking-confirm-price-modal"
    :title="$t('flight.confirmPrice')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    no-enforce-focus
    size="lg"
    @show="handleOpenModal"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="cancel()"
      >
        {{ $t('flight.Back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-danger"
        pill
        :disabled="isEmpty(payments) || (resCheckCredit && (resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent) )"
        @click="okHandle"
      >
        <div class="d-flex-center px-25">
          {{ $t('flight.paynow') }}
        </div>
      </b-button>
    </template>

    <IAmOverlay :loading="loading || loadingConfirmPrice">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <template #label>
              <div class="d-flex-between">
                <span class="text-dark font-weight-bolder">{{ $t('employee.agencies') }}</span>
                <BButton
                  variant="flat-dark"
                  class="p-25 rounded-circle"
                  @click="handleOpenModal"
                >
                  <IAmIcon
                    icon="revisionOutline"
                    size="20"
                  />
                </BButton>
              </div>
            </template>

            <v-select
              v-model="agencyManager"
              :options="agenciesManagerList"
              searchable
              label="agencyName"
              :clearable="false"
              :filterable="false"
              input-id="agency"
              :placeholder="$t('placeholderSelect')"
              :disabled="!isBooker || true"
              @open="openAgenciesManager"
              @search="searchAgenciesManager"
            >
              <template #selected-option="data">
                <div class="d-flex-center justify-content-between">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.agencyName }}
                  </span>
                </div>
              </template>

              <template v-slot:option="data">
                <div class="d-flex-center justify-content-between">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.agencyName }}
                  </span>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.agencyCode }}
                  </span>
                </div>
              </template>

              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>

              <template #no-options>
                {{ $t('flight.noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          v-if="agencyManager"
          cols="12"
          class="text-dark"
        >
          <b-row class="mb-1">
            <b-col cols="6">
              <span>
                {{ $t('reservation.agencyName') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <span class="text-airline font-weight-bold">
                {{ agencyManager.agencyName }}
              </span>
            </b-col>
          </b-row>

          <b-row
            v-if="!isMamaAgency"
            class="mb-50"
          >
            <b-col cols="6">
              <span>
                {{ $t('reservation.balanceLimit') }}
                <span
                  v-if="(resCheckCredit?.lockBalance)"
                  class="text-danger"
                > + {{ $t('lockBalance') }}</span>
                <span
                  v-if="showLimitCredit"
                  class="text-nowrap"
                > / {{ $t('reservation.limitCredit') }}</span>
                :
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right d-flex-center justify-content-end"
            >
              <BButton
                v-b-tooltip.hover.v-info
                :title="`${$t(showLimitCredit ? 'hide' : 'show')} ${$t('reservation.limitCredit')}`"
                variant="flat-info"
                class="p-50 rounded-circle"
                @click="handleChangeShowLimitCredit"
              >
                <feather-icon
                  class="cursor-pointer"
                  :icon="showLimitCredit ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </BButton>
              <span v-if="resCheckCredit && (resCheckCredit.balance || resCheckCredit.balance === 0) && (resCheckCredit.limitCredit || resCheckCredit.limitCredit === 0 )">
                {{ formatCurrency(resCheckCredit.balance) }}
                <span
                  v-if="(resCheckCredit?.lockBalance)"
                  v-b-tooltip.hover.v-danger
                  class="text-danger"
                  :title="$t('lockBalance')"
                > + {{ formatCurrency(resCheckCredit?.lockBalance) }}</span>
                <span v-if="showLimitCredit">/ {{ formatCurrency(resCheckCredit.limitCredit) }}</span>
              </span>
              <b-spinner
                v-else
                variant="info"
                style="width: 20px; height: 20px;"
              />
              <b-button
                v-if="resCheckCredit?.lockBalance && resCheckCredit?.lockedList && resCheckCredit?.lockedList.length > 0"
                v-b-tooltip.hover.v-dark
                variant="flat-dark"
                class="p-25 rounded-circle ml-25"
                :title="$t('reservation.lockedList.title')"
                @click="handleShowLockedList"
              >
                <IAmIcon
                  icon="dotsOutline"
                  size="18"
                />
              </b-button>
            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col cols="6">
              <span>
                {{ $t('reservation.ticketPrice') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <b-spinner
                v-if="!totalPayment"
                variant="info"
                style="width: 20px; height: 20px;"
              />
              <span v-else>
                {{ formatCurrency(totalPayment) }}
              </span>
            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col cols="6">
              <span>
                {{ $t('reservation.discount') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <span>
                {{ formatCurrency(promotionAmount) }}
              </span>
            </b-col>
          </b-row>
          <b-row
            v-if="promotionAmountAfter"
            class="mb-50"
          >
            <b-col cols="6">
              <span>
                {{ $t('reservation.discountAfter') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <span>
                {{ formatCurrency(promotionAmountAfter) }}
              </span>
            </b-col>
          </b-row>

          <b-row class="mb-50 font-weight-bolder">
            <b-col cols="6">
              <span>
                {{ $t('reservation.totalAmountNeedToPay') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <span v-if="totalPayment && totalPaymentNeedToPay">
                {{ formatCurrency(totalPaymentNeedToPay) }}
              </span>
              <b-spinner
                v-else
                variant="info"
                style="width: 20px; height: 20px;"
              />
            </b-col>
          </b-row>

          <hr class="font-weight-bolder">

          <b-row class="font-weight-bolder mb-50">
            <b-col cols="6">
              <span v-if="!isMamaAgency && resCheckCredit && !resCheckCredit.isOverLimit">
                {{ $t('reservation.balanceAfterPayment') }}:
              </span>
              <span v-else>
                {{ $t('flight.totalPayment') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right text-16px"
              :class="resCheckCredit ? `text-${(resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent || resCheckCredit.isWarningLimitPercent) ? 'danger' : ((resCheckCredit.isWarning || resCheckCredit.isWarningLimitPercent) ? 'warning' : 'success')}`: ''"
            >
              <div v-if="totalPayment">
                <span v-if="!isMamaAgency && resCheckCredit && !resCheckCredit.isOverLimit && resCheckCredit.balance">
                  {{ formatCurrency(Number(resCheckCredit.balance) + Number(totalPaymentNeedToPay)) }}
                </span>
                <span v-else>
                  {{ formatCurrency(Number(totalPaymentNeedToPay)) }}
                </span>
              </div>
              <b-spinner
                v-else
                variant="info"
                style="width: 20px; height: 20px;"
              />
            </b-col>
          </b-row>

          <!-- ANCHOR - Show Alert -->
          <b-row
            v-if="resCheckCredit"
            class="mb-50"
          >
            <!-- v-if="(resCheckCredit.isOverLimit || resCheckCredit.isWarningLimitPercent)" -->
            <b-col
              v-if="(resCheckCredit.isOverLimit || resCheckCredit.isWarningLimitPercent)"
              cols="12"
              class="text-center"
            >
              <b-alert
                show
                variant="danger"
                class="py-1 text-title"
              >
                <div
                  v-html="resCheckCredit.isOverLimit
                    ? $t('reservation.paymentOverLimit', { agencyCode: agencyString})
                    : resCheckCredit.isWarningLimitPercent
                      ? $t('reservation.isWarningLimitPercent', { agencyCode: agencyString, percent: resCheckCredit.warningLimitPercent })
                      : $t('reservation.paymentWarning', { agencyCode: agencyString }) "
                />
              </b-alert>
            </b-col>

            <b-col
              v-else-if="resCheckCredit.isWarning"
              cols="12"
              class="text-center"
            >
              <b-alert
                show
                variant="warning"
                class="py-1 text-title"
              >
                <div v-html="$t('reservation.paymentWarning', { agencyCode: agencyString })" />
              </b-alert>
            </b-col>

            <b-col
              v-if="resCheckCredit.isOverLimitParent"
              cols="12"
              class="text-center"
            >
              <b-alert
                show
                variant="danger"
                class="py-1 text-title my-25"
              >
                <div v-html="$t('reservation.isOverLimitParent', { agencyCode: agencyParentString })" />
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </IAmOverlay>

    <IAmModalLockedList :locked-list="lockedList" />
  </b-modal>
</template>

<script>
import {
  BModal, BCol, BRow, BSpinner, BFormGroup, BAlert, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  computed, ref, watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import vSelect from 'vue-select'

import store from '@/store'
import { formatCurrency } from '@/@core/utils/filter'
import { apiAgencies } from '@/api/'
import { getUserData } from '@/api/auth/utils'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BModal,
    BAlert,
    BCol,
    BRow,
    BSpinner,
    BFormGroup,
    BButton,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmModalLockedList: () => import('@/components/IAmModalLockedList.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    const discountAmount = ref(0)
    const promotionAmount = computed(() => {
      const total = props.bookingData?.itineraries.reduce((sum, itinerary) => {
        const isPrepaid = itinerary?.discountInfo?.paidType === 'PREPAID'
        return sum + (isPrepaid ? (itinerary?.discountInfo?.discountAmount || 0) : 0)
      }, 0)
      return total || 0
    })
    const promotionAmountAfter = computed(() => {
      const total = props.bookingData?.itineraries.reduce((sum, itinerary) => {
        const isPostpaid = itinerary?.discountInfo?.paidType === 'POSTPAID'
        return sum + (isPostpaid ? (itinerary?.discountInfo?.discountAmount || 0) : 0)
      }, 0)
      return total || 0
    })

    const { payNowConfirmPrice } = useBookingHandle()
    const payments = ref(null)
    const agencyManager = ref(null)
    const loading = ref(false)
    const loadingConfirmPrice = ref(false)
    const resCheckCredit = ref(null)
    const isDisabledPayment = ref(true)
    const isBooker = computed(() => {
      let result = false
      if (getUserData().employeeData.type === 'BE') {
        result = true
      }
      return result
    })

    const bookingId = ref()
    const totalAgencyFee = ref(0)
    const isHideFee = computed(() => store.getters['userStore/getHideFee'])

    const isMamaAgency = computed(() => {
      let result = false
      if (agencyManager.value && agencyManager.value.id === 1000000) {
        result = true
      }
      return result
    })

    const totalPayment = computed(() => {
      let total = 0
      if (payments.value) {
        total = payments.value.reduce((a, c) => a + c.pricingInfo.total, 0) - discountAmount.value + (isHideFee.value ? totalAgencyFee.value : 0)
      }

      return total
    })

    const totalPaymentNeedToPay = computed(() => totalPayment.value - promotionAmount.value + (isHideFee.value ? 0 : totalAgencyFee.value))

    async function checkCreditForPayment(agency) {
      const payload = {
        id: agency.id,
        amount: totalPaymentNeedToPay.value,
      }

      loading.value = true

      try {
        const res = await apiAgencies.checkCredit(payload.id, { amount: payload.amount })
        resCheckCredit.value = {
          ...res.data,
          isWarning: false, // ẩn cảnh báo warningLimit
          isWarningLimitPercent: false, // ẩn cảnh báo warningLimit
        }
        isDisabledPayment.value = res.data.isOverLimit
      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false
      }
    }

    watch(
      [() => agencyManager.value, () => totalPayment.value],
      ([agencyData, totalPaymentData]) => {
        if (agencyData) {
          if (agencyData.id !== 1000000 && totalPaymentData !== 0) {
            checkCreditForPayment(agencyData)
          } else { // is MAMA => no disabled
            resCheckCredit.value = {
              isOverLimit: false,
            }

            isDisabledPayment.value = false
          }
        }
      },
      { immediate: true },
    )

    function okHandle() {
      const payload = {
        bookingId: bookingId.value,
        ...cloneDeep(props.bookingData),
        payments: payments.value,
        ...((getUserData().employeeData?.agency?.agencyCode !== agencyManager.value.agencyCode) && { assignAgencyPayable: agencyManager.value.id }),
      }
      emit('create-booking', payload)
    }

    // ANCHOR - Fetch Agencies
    const agenciesManagerList = ref([])
    const agenciesManagerListDefault = ref([])

    const fetchAgenciesManagerByFilter = async (search = '') => {
      agenciesManagerList.value = []
      loading.value = true
      try {
        const { data } = await apiAgencies.fetchAgencies({ isActive: true, searchText: search })
        if (data && !search) {
          agenciesManagerListDefault.value = data.items
        }
        agenciesManagerList.value = data.items
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const openAgenciesManager = () => {
      if (agenciesManagerListDefault.value.length === 0) {
        fetchAgenciesManagerByFilter()
      } else {
        agenciesManagerList.value = agenciesManagerListDefault.value
      }
    }

    const searchAgenciesManager = debounce(search => {
      if (search) fetchAgenciesManagerByFilter(search)
    }, 300)

    function handleOpenModal() {
      totalAgencyFee.value = 0
      if (!store.getters['userStore/getRoleMama']) {
        // ANCHOR: Get agency Fee
        const { paxLists, itineraries } = props.bookingData
        let numberPax = 0
        paxLists.forEach(pax => {
          if (pax.paxType !== 'INFANT') numberPax += 1
        })
        itineraries.forEach(trip => {
          const agFee = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source)
          // FIXME agFee with transits
          totalAgencyFee.value += agFee.amount * numberPax
        })
      }
      payments.value = null

      // Paynow F1
      if (props.bookingData.itineraries.every(itinerary => itinerary.source === 'F1')) {
        payments.value = props.bookingData.paxLists?.map(passenger => ({
          paxId: passenger.paxId,
          pricingInfo: {
            currency: 'VND',
            netFare: props.bookingData.itineraries[0].fareBreakdowns[0].netFare,
            tax: props.bookingData.itineraries[0].fareBreakdowns[0].tax,
            total: props.bookingData.itineraries[0].fareBreakdowns[0].total,
          },
        }))
        discountAmount.value = props.bookingData?.discountAmount || 0
        bookingId.value = props.bookingData.bookingId || null
      } else {
        // Paynow confirm
        loadingConfirmPrice.value = true
        payNowConfirmPrice(props.bookingData)
          .then(res => {
            payments.value = res.paxLists?.map(passenger => ({
              paxId: passenger.paxId,
              pricingInfo: passenger.pricingInfo,
            }))
            discountAmount.value = props.bookingData?.discountAmount || 0
            bookingId.value = res.bookingId
          })
          .finally(() => {
            loadingConfirmPrice.value = false
          })
      }
      agencyManager.value = getUserData().employeeData?.agency

      if (agencyManager.value !== null) {
        openAgenciesManager()
      }
    }
    const showLimitCredit = ref(false)

    function handleChangeShowLimitCredit() {
      showLimitCredit.value = !showLimitCredit.value
    }

    const agencyString = computed(() => {
      const code = agencyManager.value?.agencyCode || ''
      const name = ` (${agencyManager.value?.agencyName})` || ''
      return `${code}${name}`
    })

    const agencyParentString = computed(() => {
      const code = agencyManager.value?.parentAgency?.agencyCode || ''
      const name = ` (${agencyManager.value?.parentAgency?.agencyName})` || ''
      return `${code}${name}`
    })

    const lockedList = ref([])
    function handleShowLockedList() {
      lockedList.value = resCheckCredit.value?.lockedList || []
      this.$bvModal.show('modal-locked-list')
    }

    return {
      loading,
      loadingConfirmPrice,
      isBooker,
      isMamaAgency,
      agenciesManagerList,
      resCheckCredit,
      agencyManager,
      handleOpenModal,
      discountAmount,
      promotionAmount,
      promotionAmountAfter,
      totalPayment,
      payments,
      isEmpty,
      okHandle,
      formatCurrency,
      searchAgenciesManager,
      openAgenciesManager,
      showLimitCredit,
      handleChangeShowLimitCredit,
      totalAgencyFee,
      totalPaymentNeedToPay,
      agencyString,
      agencyParentString,
      handleShowLockedList,
      lockedList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
